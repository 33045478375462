<template>
     <div class="inquiry_info">
          <div class="info_main1">
               <div class="member_title_breadcrumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right" style="line-height: 40px;font-size: 10px;">
                         <el-breadcrumb-item :to="{ path: '/member/inquiry/myInq' }">我的报价</el-breadcrumb-item>
                         <el-breadcrumb-item>询盘详情</el-breadcrumb-item>
                    </el-breadcrumb>
               </div>
               <div class="info_z">
                    <div class="infoBox2">{{ inquiry_info.info.title }}</div>
                    <div class="mod21 flex_row_between_center">
                         <div class="group3">
                              <span class="word1">报价状态：</span>
                              <span class="txt1">{{ inquiry_info.info.quoteStateValue }}</span>
                         </div>
                         <div class="flex_row_start_center">
                              <div class="group4">
                                   <span class="txt2">发布时间：</span>
                                   <span class="info2">{{ inquiry_info.info.createTime }}</span>
                              </div>
                              <div class="group5">
                                   <span class="info3">报价截止时间：</span>
                                   <span class="txt3">{{ inquiry_info.info.priceEndTime }}</span>
                              </div>
                         </div>
                    </div>

                    <div class="mod21" v-if="inquiry_info.info.refuseReason">
                         <div class="group4">
                              <span class="word1">审核拒绝原因：</span>
                              <span class="info21">{{ inquiry_info.info.refuseReason }}</span>
                         </div>
                    </div>

                    <div class="mod15">
                         <div class="mod39">
                              <div class="bar1 flex-col"><span class="word2">询盘需求</span></div>

                              <div class="word3">产品清单</div>
                              <div class="mod4 flex_row_start_center">
                                   <span class="word4">预计采购金额：</span>
                                   <span
                                        class="txt4">￥{{ inquiry_info.info.expectedAmountMin && inquiry_info.info.expectedAmountMin.toFixed(2) }}&nbsp;~&nbsp;￥{{ inquiry_info.info.expectedAmountMax && inquiry_info.info.expectedAmountMax.toFixed(2) }}</span>
                              </div>

                              <div class="layer31">
                                   <div class="table1">
                                        <el-table :data="inquiry_info.info.productList" style="width: 100%">
                                             <el-table-column prop="productName" width="250" label="产品名称"
                                                  align="center" />
                                             <el-table-column prop="spec" width="250" label="规格/型号" align="center" />
                                             <el-table-column prop="brandName" width="217" label="品牌" align="center" />
                                             <el-table-column prop="buyNum" width="220" label="购买数量" align="center" />
                                             <el-table-column width="220" label="期望单价(元)" align="center">
                                                  <template #default="scope">
                                                       <span>{{ scope.row.priceMin.toFixed(2) }}&nbsp;~&nbsp;{{ scope.row.priceMax.toFixed(2) }}</span>
                                                  </template>
                                             </el-table-column>
                                        </el-table>
                                   </div>

                                   <div class="label1 flex_row_center_center" @click="opFold"
                                        v-if="inquiry_info.info.productList && inquiry_info.info.productList.length > 3">
                                        <span class="word5">{{ isFold?'展开全部': '收起' }}</span>
                                        <span :class="isFold ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></span>
                                   </div>
                              </div>

                              <div class="mod4 flex_row_start_center">
                                   <span class="word4">期望收货日期：</span>
                                   <span class="txt4">{{ inquiry_info.info.expectedDeliverDate }}</span>
                              </div>

                              <div class="mod4 flex_row_start_center">
                                   <span class="word4">指定发货方式：</span>
                                   <span class="txt4">{{ inquiry_info.info.deliverTypeName }}</span>
                              </div>

                              <div class="mod4 flex_row_start_center">
                                   <span class="word4">详情描述：</span>
                                   <span class="txt4">{{ inquiry_info.info.description || '--' }}</span>
                              </div>

                              <div class="mod4 flex_row_start_start">
                                   <span class="word4">参考文件：</span>

                                   <div v-if="inquiry_info.info.fileList && inquiry_info.info.fileList.length">
                                        <div class="flex_row_start_center con1"
                                             v-for="(file, fileIdx) in inquiry_info.info.fileList" :key="fileIdx">
                                             <img class="icon2"
                                                  :src="file.type == 'img' ? require('@/assets/inquiry/inq_icon4.png') : require('@/assets/inquiry/inq_icon10.png')" />
                                             <span
                                                  class="word9">{{ file.type == 'img' ? `图片${fileIdx + 1}.${file.suffix}` : `文档${fileIdx + 1}.${file.suffix}` }}</span>
                                             <span class="info7" @click="downFile(file)">下载</span>
                                        </div>
                                   </div>
                                   <div v-else>
                                        <div class="flex_row_start_center con1">
                                             --
                                        </div>
                                   </div>
                              </div>

                              <div class="mod5f">
                                   <div class="bar1 flex-col"><span class="word2">交易信息</span></div>

                                   <div class="mod4 flex_row_start_center">
                                        <span class="word4">结算方式：</span>
                                        <span class="txt4">{{ inquiry_info.info.billTypeName }}</span>
                                   </div>
                                   <div class="mod4 flex_row_start_center">
                                        <span class="word4">发票要求：</span>
                                        <span class="txt4">{{ invoice[inquiry_info.info.invoiceType]}}</span>
                                   </div>
                              </div>

                              <div class="mod67">

                                   <div :class="{ mosaic: inquiry_info.info.quoteState == 1 }">
                                        <div class="bar1 flex-row">
                                             <span class="word2">收货人信息</span>
                                             <span class="word15"
                                                  v-if="inquiry_info.info.quoteState == 1">详细信息报价后可见</span>

                                        </div>

                                        <div class="mod4 flex_row_start_center">
                                             <span class="word4">企业名称：</span>
                                             <span class="txt4">{{ inquiry_info.info.enterpriseName }}</span>
                                        </div>
                                        <div class="mod4 flex_row_start_center">
                                             <span class="word4">联系人姓名：</span>
                                             <span class="txt4">{{ inquiry_info.info.receiverName }}</span>
                                        </div>
                                        <div class="mod4 flex_row_start_center">
                                             <span class="word4">手机号：</span>
                                             <span class="txt4">{{ inquiry_info.info.receiverMobile }}</span>
                                        </div>
                                   </div>

                                   <div class="mod4 flex_row_start_center">
                                        <span class="word4">收货地址：</span>
                                        <span class="txt4">{{ inquiry_info.info.receiverAddress }}</span>
                                   </div>
                                   <div class="mod4 flex_row_start_center">
                                        <span class="word4">详细地址：</span>
                                        <span class="txt4">{{ inquiry_info.info.receiverAddressDetail }}</span>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>



<script>
import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router'
import { inqUtils } from '@/utils/inquiry_utils.js'
import { ElMessage } from 'element-plus'
export default {
     setup() {
          const activeName = ref('first')
          const { proxy } = getCurrentInstance()

          const { setFileDTO, downFile } = inqUtils()

          const invoice = reactive({
               1: '无需发票',
               2: '增值税专用发票',
               3: '增值税普通发票',
          })

          const tabList = reactive({
               first: 'loading',
               second: 'loading',
               third: 'loading'
          })

          const isFold = ref(true)

          const route = useRoute()

          const inquiry_info = reactive({
               info: {}
          })

          const quoList = reactive({
               list: [],
               page: {}
          })

          const recomList = reactive({
               list: [],
               page: {}
          })

          const opFold = () => {
               isFold.value = !isFold.value
               if (isFold.value) {
                    inquiry_info.info.productList = inquiry_info.info.orProList.slice(0, 3)
               } else {
                    inquiry_info.info.productList = inquiry_info.info.orProList
               }
          }

          const getInfo = () => {
               proxy.$get('v3/business/front/supplierInquire/detail', {
                    inquireId: route.query.inquireId
               }).then(res => {
                    if (res.state == 200) {
                         inquiry_info.info = res.data
                         inquiry_info.info.orProList = res.data.productList
                         inquiry_info.info.productList = res.data.productList.slice(0, 3)
                         inquiry_info.info.fileList = res.data.referenceFileUrl.split(',').map(file => {
                              return setFileDTO({ path: file, url: file })
                         })

                         tabList.first = 'loaded'
                    }
               })
          }


          const getQuoList = () => {
               proxy.$get('v3/business/front/supplierInquire/detail', {
                    inquireId: route.query.inquireId
               }).then(res => {
                    if (res.state == 200) {
                         quoList.list = res.data.list
                         quoList.page = res.data.pagination
                         tabList.second = 'loaded'
                    }
               })
          }


          const getRecList = () => {

          }


          const handleClick = (tab) => {
               switch (tab.props.name) {
                    case 'first': {
                         tabList.first == 'loading' && getInfo()
                         break
                    }

                    case 'second': {
                         tabList.second == 'loading' && getQuoList()
                         break
                    }

                    case 'third': {
                         tabList.third == 'loading' && getRecList()
                         break
                    }
               }
          }

          const editNum = (type, scopeItem) => {
               switch (type) {
                    case 'minus': {
                         if (scopeItem.goodsNum <= scopeItem.minBuyNum) {
                              return
                         } else {
                              scopeItem.goodsNum--
                         }
                         break
                    }
                    case 'edit': {
                         if (!/^[1-9]\d*$/.test(scopeItem.goodsNum) && scopeItem.goodsNum) {
                              scopeItem.goodsNum = scopeItem.minBuyNum
                         }

                         if (scopeItem.goodsNum > 99999) {
                              scopeItem.goodsNum = 99999
                         }
                         break
                    }
                    case 'plus': {
                         if (scopeItem.goodsNum > 99999) {
                              scopeItem.goodsNum = 99999
                         } else {
                              scopeItem.goodsNum++
                         }
                         break;
                    }

                    case 'blur': {
                         if (scopeItem.goodsNum <= scopeItem.minBuyNum || !scopeItem.goodsNum) {
                              scopeItem.goodsNum = scopeItem.minBuyNum
                         }
                         break;
                    }
               }

          }

          const addCart = (scoItem) => {
               proxy.$addCart(scoItem)
          }

          const seeQuo = (quoteId) => {
               router.push({
                    path: '/inquiry/quo_info',
                    query: {
                         quoteId
                    }
               })
          }

          const acceptQuo = (quoteId) => {
               proxy.$confirm('确认接受该报价？', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
               }).then(res => {
                    proxy.$post('v3/business/front/purchaseInquire/confirmQuote', { quoteId }).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                         }
                    })
               })
          }


          onMounted(() => {
               if (route.query.inquireId) {
                    getInfo()
               }
          })


          return {
               activeName,
               inquiry_info,
               invoice,
               downFile,
               opFold,
               recomList,
               quoList,
               handleClick,
               editNum,
               addCart,
               seeQuo,
               acceptQuo
          }
     }
}
</script>


<style lang="scss" scoped>
.inquiry_info {
     background: #F7F7F7;
     padding-bottom: 20px;

     .info_main1 {
          width: 1200px;
          margin: 0 auto;

          .member_title_breadcrumb {
               padding-left: 10px;
               background: $colorK;

               .el-breadcrumb__item {
                    height: 46px;
                    line-height: 46px;
               }

               .el-breadcrumb__inner {
                    color: #666666 !important;
                    font-weight: 700 !important;
               }

               .is-link {
                    color: #666666 !important;
                    font-weight: 400 !important;

                    &:hover {
                         color: #333333 !important;
                         font-weight: 700 !important;
                    }
               }
          }

          .mod15 {
               margin-top: 30px;
          }


          .mod4 {
               margin: 21px 0 0 17px;

               .word4 {
                    color: rgba(102, 102, 102, 1);
                    font-size: 14px;
                    white-space: nowrap;
               }

               .txt4 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;


               }
          }

          .mosaic {
               .txt4 {
                    color: transparent;
                    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
               }
          }

          .bar1 {
               background-color: rgba(40, 95, 222, 0.1);
               height: 40px;
               margin: 24px 0 0 0px;

               .word2 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 16px;
                    display: block;
                    margin: 13px 0 0 15px;
               }


               .word15 {
                    color: rgba(40, 95, 222, 1);
                    font-size: 12px;
                    margin: 15px 0 0 21px;
                    display: block;
               }
          }

          .info_z {
               background-color: #fff;
               padding: 20px;
               padding-bottom: 57px;

               .infoBox2 {
                    color: rgba(51, 51, 51, 1);
                    font-size: 18px;
                    font-weight: bold;
               }

               .mod21 {
                    margin: 38px 0 0 0px;

                    .group3 {
                         .word1 {
                              color: rgba(102, 102, 102, 1);
                              font-size: 14px;
                         }

                         .txt1 {
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                         }
                    }

                    .group4 {

                         .word1 {
                              color: rgba(102, 102, 102, 1);
                              font-size: 14px;
                         }


                         .txt2 {
                              color: rgba(153, 153, 153, 1);
                              font-size: 14px;
                         }

                         .info2 {
                              color: rgba(102, 102, 102, 1);
                              font-size: 14px;
                         }

                         .info21 {
                              color: #333333;
                              font-size: 14px;
                         }
                    }

                    .group5 {
                         margin: 0px 0 0 71px;

                         .info3 {
                              color: rgba(153, 153, 153, 1);
                              font-size: 14px;
                         }

                         .txt3 {
                              color: rgba(102, 102, 102, 1);
                              font-size: 14px;
                         }
                    }
               }

               .mod39 {
                    .word3 {

                         color: rgba(51, 51, 51, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         margin: 19px 0 0 17px;
                    }

                    .layer31 {
                         margin-top: 19px;

                         .table1 {
                              border: 1px solid #DCDCDC;

                         }

                         .label1 {
                              margin-top: 19px;

                              .word5 {
                                   color: rgba(40, 95, 222, 1);
                                   font-size: 14px;
                                   display: block;
                              }

                              .el-icon-arrow-down {
                                   color: rgba(40, 95, 222, 1);
                                   font-size: 14px;
                                   margin-left: 19px;
                                   font-weight: bold;
                              }
                         }

                    }

                    .con1 {
                         margin-bottom: 10px;

                         .icon2 {
                              width: 30px;
                              height: 30px;
                              margin-left: 9px;
                         }

                         .word9 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 12px;
                              margin: 0px 0 0 16px;
                         }

                         .info7 {
                              color: rgba(40, 95, 222, 1);
                              font-size: 12px;
                              margin: 0px 0 0 24px;
                              cursor: pointer;
                         }
                    }


               }
          }

          .mod_bao {
               border: 1px solid #DCDCDC;

               .pop1 {
                    font-size: 14px;
                    color: #333;
                    margin: 5px 0;
               }

               .dao1 {
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #F72020;


               }

               .dao2 {
                    .img4 {
                         width: 50px;
                         height: 49px;
                    }

                    .TextGroup4 {
                         margin-left: 9px;

                         .paragraph4 {
                              color: #333333;
                              font-size: 12px;
                              display: block;
                              text-align: left;
                         }

                         .main1 {

                              line-height: 12px;
                              margin-top: 7px;
                              text-align: left;

                              .info15 {
                                   color: rgba(102, 102, 102, 1);
                                   font-size: 12px;
                                   text-align: left;
                                   line-height: 12px;
                              }

                              .txt12 {
                                   color: rgba(40, 95, 222, 1);
                                   line-height: 12px;
                              }
                         }
                    }
               }

               .dao3 {
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;

                    .word22 {
                         margin: 0 4px;
                    }

                    .word11 {
                         cursor: pointer;

                    }

                    .word11:hover {
                         color: #285FDE;
                    }
               }

               .bd16 {
                    width: 122px;
                    height: 46px;
                    margin-left: 10px;

                    .section25 {
                         width: 122px;
                         height: 30px;
                         justify-content: space-between;

                         .section26 {
                              height: 30px;
                              border-radius: 2px;
                              border: 1px solid rgba(221, 221, 221, 1);
                              background-color: rgba(255, 255, 255, 1);
                              width: 98px;
                              align-items: flex-start;
                              padding-left: 8px;
                              position: relative;

                              .section27 {
                                   width: 64px;
                                   height: 30px;
                                   justify-content: space-between;

                                   .info55 {
                                        width: 5px;
                                        height: 12px;
                                        display: block;

                                        color: rgba(204, 204, 204, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        text-align: left;
                                        margin-top: 8px;
                                   }

                                   .main21 {
                                        height: 30px;
                                        border: 1px solid rgba(221, 221, 221, 1);
                                        width: 48px;
                                        justify-content: center;
                                        align-items: center;

                                        .word102 {
                                             width: 14px;
                                             height: 12px;
                                             display: block;

                                             color: rgba(51, 51, 51, 1);
                                             font-size: 12px;
                                             font-family: SourceHanSansCN-Regular;
                                             white-space: nowrap;
                                             line-height: 12px;
                                             text-align: left;
                                        }
                                   }
                              }

                              .section28 {
                                   height: 30px;
                                   border-radius: 0 2px 2px 0;
                                   border: 1px solid rgba(221, 221, 221, 1);
                                   width: 26px;
                                   justify-content: center;
                                   align-items: center;
                                   position: absolute;
                                   left: 72px;
                                   top: 0;

                                   .info56 {
                                        width: 7px;
                                        height: 12px;
                                        display: block;

                                        color: rgba(34, 34, 34, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        text-align: left;
                                   }
                              }
                         }

                         .txt50 {
                              display: block;
                              color: rgba(68, 68, 68, 1);
                              font-size: 12px;
                              white-space: nowrap;
                              margin-top: 5px;
                              margin-left: 4px;
                         }
                    }

                    .section29 {

                         margin-top: 4px;


                         .txt51 {
                              word-break: break-all;
                              color: #90929E;
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              line-height: 16px;
                              text-align: left;
                         }

                         .txt52 {
                              width: 60px;
                              height: 12px;
                              display: block;

                              color: #90929E;
                              font-size: 12px;
                              font-family: SourceHanSansCN-Regular;
                              white-space: nowrap;
                              line-height: 12px;
                              text-align: left;
                         }
                    }
               }

               .goods_edit_nem1 {

                    background: #ffffff;
                    border: 1px solid #dddddd;

                    span {
                         width: 26px;
                         height: 30px;
                         background: #fff;
                         text-align: center;
                         display: block;
                         line-height: 30px;
                         cursor: pointer;
                    }

                    input {
                         width: 44px;
                         height: 30px;
                         border: none;
                         border-left: 1px solid #dddddd;
                         border-right: 1px solid #dddddd;
                         text-align: center;
                         line-height: 30px;
                    }

                    /*** 消除input元素 type="number" 时默认的 加减按钮*/
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button {
                         -webkit-appearance: none;
                         margin: 0;
                    }

                    /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
                    input[type="number"] {
                         -moz-appearance: textfield;
                    }
               }


               .wrap5 {
                    background-color: rgba(243, 247, 255, 1);
                    border-radius: 4px;
                    height: 30px;
                    border: 1px solid rgba(40, 95, 222, 1);
                    width: 93px;
                    cursor: pointer;

                    .txt9 {
                         color: rgba(40, 95, 222, 1);
                         font-size: 12px;
                         line-height: 12px;
                         margin: 8px 0 0 4px;
                    }
               }

          }
     }
}
</style>